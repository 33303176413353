<template>
  <!--   <div class="d-inline-block"> -->
  <v-text-field
    :id="id"
    v-model="password"
    v-tooltip="showPassword ? $t('password.lockShowPassword') : $t('password.lockHidePassword')"
    :class="inputClass"
    :rules="rules"

    :name="name"
    :label="label"
    :disabled="disabled"
    :value="value"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    @click:append="togglePasswordVisibility"
    @input="onInput($event)"
  >
    <template #prepend>
      <v-icon
        :icon="prependIcon"
      />
    </template>
  </v-text-field>
<!--   </div> -->
</template>

<script>
export default {
  name: 'VuetifyPassword',

  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array
    }, // The rules object defined in the parent component
    value: {
      type: String,
      default: ''
    }, // Using v-model for the password value
    prependIcon: {
      type: String,
      default: 'mdi-lock'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: this.value,
      showPassword: false
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword

      // Start a timer to hide the password after 5 seconds
      if (this.showPassword) {
        setTimeout(() => {
          this.showPassword = false
        }, 5000) // 5000 milliseconds = 5 seconds
      }
    },
    onInput($event) {
      // console.log('oninput', $event)
      this.$emit('input', $event)
    }
  }
}
</script>
