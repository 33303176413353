import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import translations
import en from './locales/en.json'
import hu from './locales/hu.json'

Vue.use(VueI18n)

async function loadLocaleMessages() {
  // const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const localesContext = import.meta.glob('./locales/*.json')

  const messages = {}
  const localeKeys = Object.keys(localesContext)

  for (const key of localeKeys) {
    const matched = key.match(/\/([A-Za-z0-9-_]+)\.json$/i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = await import(key)
    }
  }
  // console.log(messages)
  return messages
}

const messages = {
  en: {
    ...en
  },
  hu: {
    ...hu
  }
}

const i18n = new VueI18n({
  locale: import.meta.env.VITE_VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  // messages: loadLocaleMessages() //this not working at runtime, since switched to vite
  messages
})

// Hot updates
if (import.meta.hot) {
  import.meta.hot.on('locales-update', (data) => {
    i18n.setLocaleMessage('en', en.default)
  })
}

export default i18n
