import axios from 'axios'
import Vue from 'vue'

const baseConfig = {
  baseURL: import.meta.env.VITE_VUE_APP_FORGE_API_URL,
  //timeout: 60000,
  timeout: 5000,
  headers: {
    Accept: 'application/json'
  }
}

async function handleError(error) {
  const status = error.response ? error.response.status : null

  if (status === 401) {
    const idToken = await Vue.prototype.$auth.getIdToken(true)
    const { config } = error
    config.headers.Authorization = `Bearer ${idToken}`

    return axios.request(config)
  }

  return Promise.reject(error)
}

async function createAxios(withAuth = true) {
  const config = baseConfig
  if (withAuth) {
    const idToken = await Vue.prototype.$auth.getIdToken()
    config.headers.Authorization = `Bearer ${idToken}`
  }

  const instance = axios.create(config)
  instance.interceptors.response.use(response => response, handleError)

  return instance
}

export default createAxios
