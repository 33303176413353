import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#3e92e3',
        secondary: '#f5521c',
        accent: '#3D87E4',
        success: '#2dd36f',
        warning: '#ffc409',
        danger: '#eb445a',
        tertiary: '#5260ff',
        dataTypeReal: '#2bb649',
        realContrast: '#000000',
        generated: '#eca400',
        generatedContrast: '#000000',
        provided: '#1754cf',
        providedContrast: '#ffffff',
        none: '#eb445a',
        custom: '#1754cf',
        step100: '#e6e6e6',
        step350: '#a6a6a6',
        step850: '#262626'
      }
    }
  }
})
